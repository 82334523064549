import React from "react";
// import "bootstrap/scss/bootstrap.scss";
import "../scss/global.scss";
import { createInertiaApp } from "@inertiajs/react";
import { createRoot } from "react-dom/client";
import ConfigProvider from "./contexts/ConfigContext";
import { AppContextProvider } from "../hooks/appContext";
import { getCurrentOrganisationConfig } from "./config/getOrganisationConfig";
import TagManager from "react-gtm-module";
import SentryErrorBoundary from "./utils/sentry";

const config = getCurrentOrganisationConfig();

const tagManagerArgs = {
    gtmId: config?.googleTagId,
};

TagManager.initialize(tagManagerArgs);


createInertiaApp({
    progress: {
        color: "#eb7d00",
        includeCSS: true,
        showSpinner: true,
    },
    resolve: (name) => {
        return import(`./Pages/${name}`);
    },
    setup({ el, App, props }) {
        createRoot(el).render(
            <SentryErrorBoundary>
                <ConfigProvider config={config}>
                    <AppContextProvider>
                        <App {...props} />
                           {/* OptanonCookieSettingsButtonStart */}
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">CookieSettings</button>
                           {/* OptanonCookieSettingsButtonEnd */}
                    </AppContextProvider>
                </ConfigProvider>
            </SentryErrorBoundary>
        );
    },
});
